import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 50000 // request timeout
})
service.interceptors.request.use(
    config => {
        // store.getters.token
        if (getToken()) {
            config.headers['Token'] = getToken()
            if (config.method === 'post' && config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers['Content-Type'] = 'application/json;charset=UTF-8';
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
let noMsg = [700009];
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code == undefined) {
            return res
        }
        if (res.code !== 200) {
            // if (!noMsg.includes(res.code)){
            //   if (res.msg.indexOf("缺少权限")===-1){
            //     Message({
            //       message: res.msg || 'Error',
            //       type: 'error',
            //       duration: 5 * 1000,
            //     })
            //   }
            // }
            //
            // if (res.msg.indexOf("token") >= 0){
            //   router.push({ path: '/login' })
            // }
            // if (res.msg.indexOf("缺少权限")>=0){
            //   router.push({ path: '/login' })
            // }
            // 判断企业端用户

            if (res.code === 900017) {
                MessageBox.alert('企业端用户请使用 ' +
                    '<a href="https://c.xy-sy.com/" target="_blank"> https://c.xy-sy.com/ </a> ' +
                    '域名进行登录',
                    '提示',
                    {
                        dangerouslyUseHTMLString: true
                    });
                return
            }
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 5 * 1000,
            })

            return Promise.reject(res)
        } else {
            return res;
        }
    },
    error => {
        Message.closeAll();
        if (error.response.data.msg && error.response.data.msg.indexOf("token") >= 0) {
            Message({
                message: "你的登录状态已经失效,请重新登录！",
                type: 'error',
                duration: 5 * 1000,
            })
            store.dispatch('user/loginOut').then(() => {
                location.reload()
                // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
            });
        } else {
            if (!noMsg.includes(error.response.data.code) && error.response.data.msg) {
                Message({
                    message: error.response.data.msg,
                    type: 'error',
                    duration: 5 * 1000,
                })
            }

        }
        return Promise.reject(error)
    }
)

export default service
